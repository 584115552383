.zoomDH{
    width: 600px;
    height: auto;
    cursor: zoom-in;
}
.zoomDW{
    width: 600px;
    height: auto;
    cursor: zoom-in;
}

/* [LIGHTBOX BACKGROUND] */
#lb-back-h {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#2e2e37e0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: none;
    transition: all ease 0.2s;
}
#lb-back-h.show {
    visibility: visible;
    opacity: 1;
    cursor: zoom-out;
}
#lb-back-w {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#2e2e37e0;
    /*background: rgba(248, 248, 248, 0.9);*/
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: none;
    transition: all ease 0.2s;
}
#lb-back-w.show {
    visibility: visible;
    opacity: 1;
    cursor: zoom-out;
}
/* [LIGHTBOX IMAGE] */
#lb-img-w {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

#lb-img-h {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
#lb-img-w img {
    /* You might want to play around with
       width, height, max-width, max-height
       to fit portrait / landscape pictures properly. */
    width: 90vw !important;
    margin:0vw !important;
    height: auto !important;
    cursor: zoom-out;
    /* ALTERNATE EXAMPLE
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto; */
}

.box p{
    margin: 0 0 6px 0px !important;
    color:#ccc;
    font-size: 10px;
    font-family: PublicSans-SemiBold;
}
.box b{
    margin:0 !important;
    color:#000;
    font-size: 10px;
    font-family: PublicSans-SemiBold;
}

.box1 p{
    margin:0 0 6px 0px  !important;
    color:#404040;
    font-size: 11px;
    font-family: PublicSans-SemiBold;
    line-height:normal;
    height:40px;

}
.box1 b{
    margin:0 !important;
    color:#000;
    font-size: 12px;
    line-height:27px;
    font-family: PublicSans-SemiBold;
}

#lb-img-h img {
    height: 70vh !important;
    margin:0vw !important;
    width: auto !important;
    cursor: zoom-out;

}

#row-1-light,#row-2-light b{
    color:#000;
    font-size: 12px;
    font-family: PublicSans-SemiBold;
}
#row-1-light,#row-2-light p{
    color:#333;
    font-size: 11px;
    line-height:14px;
    font-family: PublicSans-SemiBold;
}

#row-2-dark,#row-1-dark b{
    color:#ddd;
    font-size: 12px;
    font-family: PublicSans-SemiBold;
}
#row-2-dark,#row-1-dark p{
    color:#ccc;
    font-size: 11px;
    line-height:14px;
    font-family: PublicSans-SemiBold;
}