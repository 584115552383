#root{
    background-color: white
}


.todo-list{
    margin:auto;
    margin-top:60px;
    width: 100%;
    display: flex;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    /*align-items: center;*/
    /*justify-content: left;*/
    /*padding: 20px;*/
}

.pofo-card {
    /*display: block;*/
    /*align-items: center;*/
    /*text-align: center;*/
    padding: 0px 0px 6vh;
    /*margin: 5px 0px 5px;*/
    /*padding: 10px;*/
    width: 100vw;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    float:left;
    flex-direction:row;
    width: 100%;
    min-height: 30vw;
    /*font-family: Apercu-Bold, sans-serif;*/
    color:#333333;
    background-color:#f8f8f8;
    overflow: hidden;
    margin-right:0;
}
.pofo-card .mobile{

}
.pofo-card-texts {
    width: 45vw;
    float:left;
    flex-direction:row;
    padding-top:14vh;
    padding-left:15vw;
    padding-bottom: 20px;
}
.pofo-card-texts.mobile {
    width: 80vw;
    float:left;
    flex-direction:row;
    padding-top:24px;
    padding-left:10vw;
}

.pofo-card-3d-container.mobile {
    width: 100vw;
    height: 320px;
    text-align: center;
    paddingLeft: 0;
}
.pofo-card-3d-view.mobile {
    width: 80vw;
    height: 80vw;
    display: inline-block;
}


.pofo-card-texts .based-on-text{
    display: inline-block;
}
.pofo-card-texts h3{
    display: table;
    width:auto;
    font-size: 34px;
    font-family: MarkPro;
    letter-spacing: 1px;
    padding-bottom:1.5vh;
    border-bottom: solid 2px rgba(0,0,0,.2);
    border-width: 2px;
    margin-bottom:2vh;
    line-height: 95%;
 }
.pofo-card-texts.mobile h3{
    display: table;
    width:auto;
    font-size: 30px;
    font-family:MarkPro;
    padding-bottom:10px;
    border-bottom: solid 2px rgba(0,0,0,.1);
    border-width: 2px;
    margin-bottom:1vh;
    line-height: normal;
}
.pofo-card-texts.mobile p{
    font-size: 18px;
    margin-top:20px;
    line-height: 26px;
}
.pofo-card-texts.mobile h4{
    width: 80vw;
    padding-top:2px;
    padding-right:6vw;
    color:#4EA6E6;
    opacity:0.8;
    font-size: 14px;
    font-family: MarkPro;
}

.pofo-card-texts h3:hover{
    border-color: rgba(0,0,0,1);
    transition: border-color .4s;
}
.pofo-card-texts h4{
    width: 40vw;
    padding-top:5vh;
    padding-right:6vw;
    color:#4EA6E6;
    opacity:0.9;
    font-size: 15px;
    font-family: MarkPro;
}
.pofo-card-texts p{
    color:#666;
    font-size: 20px;
    margin-top:0;
    padding-right:5vw;
    line-height: 28px;
}

input[type=checkbox] {
    margin-right: 10px;
    font-size: 30px;
}

input[type=checkbox]:focus{
    outline:0;
}