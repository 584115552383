.intro-text-row{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin:10vh 0;
    line-height: 20px;
}

.intro-text-col{
    font-size: 14px;
    margin:3vw;
    width:40vw;
    text-align: left;
}
.intro-text-col p{
    color:#000;
}
.intro-img{
    float:right;
}
.intro-img img{
    float:left;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width:16vw;
    hight:16vw;
    margin:5vw;
}

.exp-text-row{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    margin-top:0vh;
    line-height: 16px;
    align-items: center;
    justify-content: center;
    background-color:#fff;
    padding:15vh 0;
}
.exp-text-col{
    text-align: left;
    width:66vw;
}
.exp-text-row h2{
    padding:0 0 5vh;
    font-family: MarkPro;
}
.exp-text-row h5{
    inline:block;
    float:none;
    color:#4EA6E6;
    margin:1.2vh 0;
    padding:0;
    border-bottom: 1px solid #4EA6E6;
    font-size:11px;
    line-height: 13px;
}
.exp-text-row b{
    display:flex;
    float:left;
    font-size:13px;
    margin:0;
    padding-left:6vw;
    line-height: 16px;
}
.exp-text-row p{
    padding-bottom:.5vh;
    padding-left:6vw;
    margin:0;
}

.skill-text-row{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    /*margin-top:10vh;*/
    line-height: 16px;
    align-items: center;
    justify-content: center;
    padding:15vh 0;
}
.skill-text-col{
    text-align: left;
    width:66vw;
}
.skill-text-row h2{
    font-family: MarkPro;
    padding:0 0 5vh;
}
.skill-text-row h5{
    inline:block;
    float:none;
    margin:1.6vh 0;
    border-bottom: 1px solid #777;
    font-size:14px;
    line-height: 20px;
}
.skill-text-row p{
    display:flex;
    font-size:13px;
    margin:0;
    padding-left:6vw;
    line-height: 16px;
    padding-bottom:1vh;
    color:#000;
}
.skill-text-italic{
    inline:block;
    float:none;
    font-size:12px;
    color:#666;
    margin-top:2vh;
    margin-bottom:0;
    padding-left:6vw;
}

.contact-text-row{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    margin-top:0vh;
    line-height: 16px;
    align-items: center;
    justify-content: center;
    padding:15vh 0;
}
.contact-text-col{
    text-align: left;
    width:66vw;
}
.contact-text-row b{
    display:flex;
    float:left;
    margin-left:6vw;
    width:6vw;
    font-size:13px;
    line-height: 20px;
    /*padding-bottom:1vh;*/
}
.contact-text-row p{
    display:flex;
    font-size:13px;
    margin:0;
    padding-left:0vw;
    line-height: 16px;
    padding-bottom:4px;
    padding-top:3px;
    color:#000;
}
.contact-text-row h2{
    padding:0 0 5vh;
    font-family: MarkPro;
}

.alt-b b{
    float:left;
    font-size:13px;
    width:12vw;
}
.alt-b p{
    display:flex;
    font-size:13px;
    margin:0;
    padding-left:0vw;
    line-height: 16px;
    padding-bottom:1vh;
    color:#000;
}