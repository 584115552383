.pofo-page img{
    width:72vw;
    height: auto;
    /*overflow: scroll;*/
    margin-top: .8vw;
    margin-left: 3vw;
    margin-bottom: 1.8vw;
}
.pofo-page.mobile img{
    width:90vw;
    height: auto;
    /*overflow: scroll;*/
    margin-top:0vw;
    margin-bottom:0.5vh;
}
.pofo-page{
    margin-top:56px;
    position: relative;
    /*overflow: scroll;*/
    height: 100vh;
}
.pofo-page.mobile{
    margin-top:100px;
}
.pofo-page h2{
    display: inline-block;
    padding-top:9vw;
    padding-bottom:3vw;
    font-size: 34px;
    font-family: MarkPro;
    letter-spacing: 1.5px;
    margin:0vw;
    line-height:45px;
    font-weight: bolder;
}

h4{
    /*font-family: Oxygen-Bold;*/
    font-family: BasisGrotesquePro-Regular;
    font-size: 22px;
    letter-spacing: 0.01em;
    letter-spacing: 1px;
    line-height:32px;
    display: inline-block;
    padding-top:1vw;
    padding-bottom:0vh;
    margin:0;
    font-weight: bold;
    /*padding-bottom: 1vw;*/
}
/*.subgroup h4{*/
/*    width:80vw;*/
/*    paddding-left:17vw;*/
/*    padding-right:17vw;*/
/*}*/

.pofo-text-row{
    /*font-family: PublicSans-Light;*/
    font-family: BasisGrotesquePro-Regular;
    letter-spacing: 0.01em;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 1vw;
    padding-top:1vw;
    font-size:15px;
    /*margin-top: 1vh;*/
}

.pofo-text-row b{
    /*font-family: PublicSans-SemiBold;*/
    font-family: BasisGrotesquePro-Medium;
    padding-bottom: 1vh;
    font-size:15px;
    letter-spacing: 0.02em;
    font-weight: normal;
}


.pofo-text-col{
    /*font-family: PublicSans-Light;*/
    font-family: BasisGrotesquePro-Regular;
    display: flex;
    flex-direction: column;
    flex-basis: 100% ;
    flex: 1;
    width:50vw;
    padding-right:5vw;
    /*padding-top:0vw;*/
    padding-top:1vh;
    letter-spacing: 0.01em;
    font-size:15px;
}
.pofo-text-col p {
     margin-top:0.4em;
     margin-bottom:0.4em;
     padding-bottom:1vh;
     /*font-size:10px;*/
 }
.pofo-text-col b {
    /*margin-top:0.4em;*/
    margin-bottom:0.4em;
    /*padding-top:1vh;*/
    /*padding-bottom:1vh;*/
    /*font-size:10px;*/
}
.pofo-text-col-sticky{
    /*display: flex;*/
    flex-direction: column;
    flex-basis: 100% ;
    flex: 1;
    width:30vw;
    padding-right:1vw;
    /*padding-top:0vw;*/
    padding-top:1vh;
    letter-spacing: 0.5px;
    font-size:15px;
    /*margin-bottom:0.6em;*/
    padding-bottom:1vh;
    position: -webkit-sticky;
    position:sticky;
    top:0;
    align-self: flex-start;
}
.pofo-text-col-sticky h4 {
    padding-bottom:1vh;
    font-weight: bolder;
    font-size:18px;
}

.pofo-img-col{
    display: flex;
    flex-direction: column;
    flex-basis: 100% ;
    flex: 1;
    width:40vw;
    margin-right:1vw;
    margin-left:0vw;
    margin-bottom:4vh;
}
.pofo-img-col img{
    width:30vw;
    margin-top:0vw;
    padding-bottom:0vw;
}
.pofo-img-col-small img{
    width:80vw;
    padding-left:15vw;
    padding-right:15vw;
    width:36vw;
    margin-top:3vw;
}
.pofo-text-col-small{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-basis: 100% ;*/
    /*flex: 0.7;*/
    width:49vw;
    padding-left:14vw;
    padding-right:15vw;
    /*margin-left:10vw;*/
    /*margin-right:2vw;*/
    padding-top:3vw;
    font-size:15px;
}

.pofo-text-col-col{
    padding-bottom:0.5vh;
    /*white-space: nowrap;*/
    /*overflow-x: auto;*/
    /*flex-direction:row;*/
    /*flex-wrap: wrap;*/
    /*flex-basis: 100% ;*/
    /*flex: 1;*/
    /*display:inline-block;*/
}

.pofo-text-col-col p{
    float:left;
    flex-direction:row;
    margin-top:1.5vh;
    font-size:15px;
}
.pofo-text-col-col b{
    font-size:15px;
}
.toggle-img{
    display:block;
    margin-top:2vh;
    position:relative;
    float:left;
    margin-right:1vw;
}
.toggle-img p{
    margin:0;
    padding:0;
    font-size:11px;
}
.toggle-img img{
    width:120px;
    height:90px;
    opacity:0.5;
    cursor: pointer;
}
.toggle-label{
    display:block;
    margin-top:1vh;
    position:relative;
    float:left;
    margin-right:.6vw;
}
.toggle-label p{
    letter-spacing: 0.01em;
    margin:0;
    padding:0;
    font-size:11px;
}
.toggle-label img{
    width:90px;
    opacity:0.4;
    cursor: pointer;
}
.pofo-v-col {
    float:left;
    width:30vw;
    padding-top:0;
    /*padding-left:5vw;*/
    flex-direction:row;
}
