.navbar {
    margin:0;
    padding:0;
    height: 56px;
    background: transparent;
    /*background-color: #f8f8f8;*/
    box-shadow:none;
    /*box-shadow: 1px 1px 4px rgba(0, 0, 0, .05);*/
    display:flex;
    /*justify-content: space-around;*/
    z-index: 100;
    position: fixed; /* Make it stick/fixed */
    top: 0; /* Stay on top */
    width: 100%; /* Full width */
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    text-decoration: none;
}
.logo{
    width:64px;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 4px;
}
/*.navbar-menu{*/
    /*width:70vw;*/
    /*display: flex;*/
    /*align-items: right;*/
    /*!*text-align: center;*!*/
    /*flex-direction:row;*/
    /*flex-wrap: wrap;*/
/*}*/

.navbar h1{
    font-size: 21px;
    color: #52A4E0;
    margin-left:4vw;
    margin-top:17px;
    text-decoration: none;
}
#logo{
    width:64px;
    /*float: left;*/
    margin-left:2vw;
    /*align-items: flex-end;*/
    /*display: flex;*/
}
.Menu{
    padding-right:2vw;
    width:90vw;
    display: block;
    /*align-items: right;*/
    text-align: right;
    flex-direction:row;
    flex-wrap: wrap;
    float:right;
}
.navbar h2{
    margin-top:0px;
    margin-bottom:0px;
    font-size: 14px;
    line-height:56px;
    font-family: MarkPro-Medium;
    color:#777;
    letter-spacing: 1.5px;
    margin-right:2vw;
    text-align: center;
    /*float: right;*/
}
.navbar h2:hover{
    cursor:pointer;
    color:#222;
    text-shadow: 1px 1px 0px #87CEF0;
}

/*#navbar {*/
    /*background-color: #333; !* Black background color *!*/
    /*position: fixed; !* Make it stick/fixed *!*/
    /*top: 0; !* Stay on top *!*/
    /*width: 100%; !* Full width *!*/
    /*transition: top 0.3s; !* Transition effect when sliding down (and up) *!*/
/*}*/

/* Style the navbar links */
/*#navbar a {*/
    /*float: left;*/
    /*display: block;*/
    /*color: white;*/
    /*text-align: center;*/
    /*padding: 15px;*/
    /*text-decoration: none;*/
/*}*/

/*#navbar a:hover {*/
    /*background-color: #ddd;*/
    /*color: black;*/
/*}*/
/*#navbar {*/
    /*transition: top 0.5s;*/
/*}*/

/*.active {*/
    /*text-decoration: underline;*/
/*}*/

/*.navlink {*/
    /*display: block;*/
    /*padding: 0.5rem 1rem;*/
/*}*/

/*.navbarbrand {*/
    /*display: inline-block;*/
    /*padding-top: .3125rem;*/
    /*padding-bottom: .3125rem;*/
    /*margin-right: 1rem;*/
    /*font-size: 1.25rem;*/
    /*line-height: inherit;*/
    /*white-space: nowrap;*/
/*}*/