.side-nav >div> a {
    font-family: MarkPro-Medium;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #C6C6C6;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:0em;
    text-decoration: none;
    display: block;
    border-top: 1px solid #EfEfEf;
    letter-spacing: 0px;
}
@media screen and (max-width: 600px) {
    .side-nav {
        visibility: hidden;
        clear: both;
        float: left;
        display: none;
    }
}

.side-nav > ul> li> a {
    font-family: MarkPro-Medium;
    font-size: 14px;
    text-align: left;
    color: #C6C6C6;
    letter-spacing: 0px;
    margin-left: 0.3em;
    /*margin-right: 20px;*/
    padding-left: 4px;
    padding-bottom: 8px;
    text-decoration: none;
    display: block;
    /*border-bottom: 1px solid  #EfEfEf;*/
}
.side-nav > ul{
    margin:0;
}

.side-nav{
    width: 14.8vw;
    margin: 0px 1.5vw;
    /*margin: 0px 20px;*/
    border-bottom: 1px solid #EaEaEa;
}

#side-nav li{
    list-style: none;
}

ul{
    list-style: none;
    padding-left: 0;
}

/*#side-nav > a.active{*/
    /*border-bottom-style: solid;*/
    /*border-width: 3px;*/
    /*border-color: #4590f7;*/
    /*color: #4590f7;*/
/*}*/


.text-highlight {
    box-shadow: 0 0 50px -20px #b7d4fc;
}

#side-nav >div > a.active{
    color: #000;
}

.side-nav > ul> li> a.active {
    color: #000;
}