body {
  margin: 0;
  padding: 0;
  width:100%;
  background-color:#f8f8f8;
  font-size:14px;
  line-height: 20px;
  font-family: PublicSans-Light, Apercu, MarkPro;
  letter-spacing: 0.035em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {width:100%;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1{
  font-family: MarkPro-Medium;
}

b{
  font-family: PublicSans-Light;
  letter-spacing: 1px;
}
h6{
  /*font-family: Oxygen-Bold;*/
  /*font-family: Apercu-Light;*/
  /*font-family: MarkPro;*/
  font-size: 20px;
  line-height:1.5;
  letter-spacing: 1px;
  /*font-size: 24px;*/
  display: inline-block;
  padding-bottom:0vh;
  width:80vw;
  color:#666;
  opacity:0.9;
  margin:0;
}
h7{
  /*font-family: PublicSans-Medium;*/
  /*font-size: 28px;*/
  font-size: 34px;
  font-family: MarkPro-Medium;
  color:#000;
  z-Index:99;
  opacity:1;
  line-height:1.8;
  letter-spacing: 0px;
  display: inline-block;
  width:80vw;

}

@media screen and (max-width: 600px) {
  .footer {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;


  }
  .footer h4{
    font-size:14px;
    margin-bottom: 0px;
  }
}