@font-face{
    font-family: Source-Sans-Pro;
    src: url("../fonts/SourceSansPro-Regular.ttf");
}

@font-face{
    font-family: Apercu-Bold;
    src: url("../fonts/Apercu-Bold.otf");
}

@font-face{
    font-family: Apercu-Medium;
    src: url("../fonts/Apercu-Medium.otf");
}

@font-face{
    font-family: Apercu;
    src: url("../fonts/Apercu.ttf");
}

@font-face{
    font-family: Apercu-Light;
    src: url("../fonts/Apercu-Light.otf");
}
@font-face{
    font-family: Oxygen-Bold;
    src: url("../fonts/Oxygen-Bold.ttf");
    font-weight: bold;
}

@font-face{
    font-family: Oxygen-Light;
    src: url("../fonts/Oxygen-Light.ttf");
}

@font-face{
    font-family: Oxygen-Regular;
    src: url("../fonts/Oxygen-Regular.ttf");
}


/*** NEW FONTS ***/
@font-face{
    font-family: MarkPro-Medium;
    src: url("../fonts/MarkPro-Medium.ttf");
}
@font-face{
    font-family: MarkPro;
    src: url("../fonts/MarkPro.ttf");
}

@font-face{
    font-family: PublicSans-Light;
    src: url("../fonts/PublicSans-Light.ttf");
}
@font-face{
    font-family: PublicSans-ExtraLight;
    src: url("../fonts/PublicSans-ExtraLight.ttf");
}
@font-face{
    font-family: PublicSans-ExtraLightItalic;
    src: url("../fonts/PublicSans-ExtraLightItalic.ttf");
}

@font-face{
    font-family: PublicSans-Medium;
    src: url("../fonts/PublicSans-Medium.ttf");
}

@font-face{
    font-family: PublicSans-Regular;
    src: url("../fonts/PublicSans-Regular.ttf");
}

@font-face{
    font-family: PublicSans-MediumItalic;
    src: url("../fonts/PublicSans-MediumItalic.ttf");
}

@font-face{
    font-family: PublicSans-SemiBold;
    src: url("../fonts/PublicSans-SemiBold.ttf");
}



@font-face{
    font-family: BasisGrotesquePro-Black;
    src: url("../fonts/BasisGrotesquePro-Black.ttf");
}
@font-face{
    font-family: BasisGrotesquePro-Bold;
    src: url("../fonts/BasisGrotesquePro-Bold.ttf");
}
@font-face{
    font-family: BasisGrotesquePro-Medium;
    src: url("../fonts/BasisGrotesquePro-Medium.ttf");
}
@font-face{
    font-family: BasisGrotesquePro-Regular;
    src: url("../fonts/BasisGrotesquePro-Regular.ttf");
}
@font-face{
    font-family: BasisGrotesquePro-Light;
    src: url("../fonts/BasisGrotesquePro-Light.ttf");
}



/*{*/
/*border: 1px solid #efefef;*/
/*box-shadow:*/
/*!* the top layer shadow     *!*/
/*0 1px 1px rgba(0,0,0,0.15),*/
/*!* the second layer *!*/
/*0 10px 0 -5px #eee,*/
/*!* the second layer shadow *!*/
/*0 10px 1px -4px rgba(0,0,0,0.15),*/
/*!* the third layer *!*/
/*0 20px 0 -10px #eee,*/
/*!* the third layer shadow *!*/
/*0 20px 1px -9px rgba(0,0,0,0.15);*/
/*}*/